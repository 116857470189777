@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');

html, body {
  height: 100%;
}

#root, .App {
  min-height: 100%;
}

html, body, #root, .App {
  width: 100%;
}